import { Box } from '@mui/material'
import React from 'react'
import JoinEmployeeTable from '../components/join_employee_table'

const EmplyementPage = () => {
        return (
                <Box >
                        <br/><br/>
                        <JoinEmployeeTable />
                </Box>
        )
}

export default EmplyementPage
