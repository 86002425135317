import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useSelector } from 'react-redux';
import Err401 from '../../../assets/SVGs/err401'
import Load from '../../../components/load'
import axios from "axios";

import Container from 'react-bootstrap/Container';
//import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

  

const Requests = () =>{
    const acc=useSelector((state) => state.counter.account);
    const Lang=useSelector((state) => state.counter.language);
    const token = useSelector((state) => state.counter.token);

    const [open, setOpen] = React.useState(false);
    const [loading,setLoading]=React.useState(false);
    const [data,setData]=React.useState([]);


    const total=(products)=>{
      var total=0;
      for(var i=0;i<products.length;i++)
      {
        total=total+( products[i].required_quantity*(products[i].offer_percentage ?  ((products[i].price-(products[i].price*products[i].offer_percentage/100))*products[i].required_quantity) : (products[i].required_quantity * products[i].price)) )
      }
      return (total)
    }

    React.useEffect(() => {
      setLoading(true);
      axios.get("https://rest.istanbulru.com/api/showUserRequests",{
        headers:{
            "Accept":"application/json",
            "Authorization":"Bearer "+token
        }
      })
        .then((response) => {
          console.log(response.data);
          setData(response.data.requests)
          setLoading(false)
      })
        .catch((error) => {console.log(error)
          setLoading(true);
        });
      
  }, []);


    if(acc!==1)
    return(
        <>
        <Load run={loading} />
        <Err401 />
            <h4>
                {(Lang === "Ar" ? ("يجب عليك تسجيل الدخول أولا") : Lang === "En" ? ("you have to login first") : " сначала вам нужно войти в систему")}
            </h4>
        </>
    )

    return(
        <Container>
            <Col lg={12} xs={12} >
              <TableContainer component={Paper}>
                <Load run={loading} />
                  <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell align="start" > {Lang==="Ar" ? (" تاريخ الطلب ") : Lang==="En"? (" date ") : "Дата подачи заявления"} </TableCell>
                            <TableCell align="start"> {Lang==="Ar" ? (" حالة الطلب ") : Lang==="En"? (" state ") : "Статус заказа"} </TableCell>
                            <TableCell align="start">  {Lang==="Ar" ? (" عدد العناصر ") : Lang==="En"? (" total ") : "Количество предметов"} </TableCell>
                            <TableCell align="start"> {Lang==="Ar" ? ("  سعر الطلب ") : Lang==="En"? (" price ") : "Спроси цену"} </TableCell>
                            <TableCell align="start"> {Lang==="Ar" ? ("  طريقة الدفع ") : Lang==="En"? (" payment method ") : "способ оплаты"} </TableCell>
                            <TableCell align="start"> {Lang==="Ar" ? (" حالة التوصيل ") : Lang==="En"? (" delivery ") : "Возможность доставки"} </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody >
                        {data.map((row) => (
                          <React.Fragment>
                          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                            <TableCell>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => setOpen(!open)}
                              >
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                              </IconButton>
                            </TableCell>
                            <TableCell align="start" component="th" scope="row">
                              {row.created_at}
                            </TableCell>
                            <TableCell align="start">{row.request_state}</TableCell>
                            <TableCell align="start">{row.products.length  }</TableCell>
                            <TableCell align="start">{total(row.products)+row.delivery_ser_price}₽</TableCell>
                            <TableCell align="start">{row.payment}</TableCell>
                            <TableCell align="start">{row.delivery_ser_city_name ? (row.delivery_ser_city_name+" _ "+row.delivery_ser_price+"₽") : (Lang==="Ar" ? ("بدون توصيل ") : Lang==="En"? (" Without delivery ") : " Без доставки ")}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                              <Collapse in={open} timeout="auto" unmountOnExit>
                                <Box sx={{ margin: 1 }}>
                                  <Typography variant="h6" gutterBottom component="div">
                                  {Lang==="Ar" ? ("تفاصيل الطلب") : Lang==="En"? (" Order details ") : " Информация для заказа "} 
                                  </Typography>
                                  <Table style={{     minWidth: "400px" }} size="small" aria-label="purchases">
                                    <TableHead >
                                      <TableRow>
                                        <TableCell align="start">  {Lang==="Ar" ? ("اسم العنصر ") : Lang==="En"? (" name ") : " имя "} </TableCell>
                                        <TableCell align="start"> code </TableCell>
                                        <TableCell align="start"> {Lang==="Ar" ? ("السعر ") : Lang==="En"? (" price ") : " Цена "} </TableCell>
                                        <TableCell align="start"> {Lang==="Ar" ? (" الكمية ") : Lang==="En"? (" quantity ") : " Количество "}  </TableCell>
                                        <TableCell> {Lang==="Ar" ? (" نسبة العرض ") : Lang==="En"? (" Offer ratio ") : " Коэффициент предложения "} </TableCell>
                                        <TableCell align="start"> {Lang==="Ar" ? (" المجموع ") : Lang==="En"? (" Total price ") : " Итоговая цена "}</TableCell>
                                      </TableRow>
                                      </TableHead>
                                      <TableBody >
                                          {row.products.map((historyRow) => (
                                          <TableRow  key={historyRow.name}>
                                              <TableCell component="th" scope="row">
                                              {historyRow.name}
                                              </TableCell>
                                              <TableCell>{historyRow.code}</TableCell>
                                              <TableCell>{historyRow.price}₽</TableCell>
                                              <TableCell >{historyRow.required_quantity}</TableCell>
                                              <TableCell >{  historyRow.offer_percentage ? (historyRow.offer_percentage +"%") : (Lang==="Ar" ? (" بدون عرض ") : Lang==="En"? (" No offer ") : " Нет предложения ") }</TableCell>
                                              <TableCell align="start">
                                                {historyRow.offer_percentage ?  ((historyRow.price-(historyRow.price*historyRow.offer_percentage/100))*historyRow.required_quantity) : (historyRow.required_quantity * historyRow.price)}₽
                                              </TableCell>
                                          </TableRow>
                                          ))}
                                      </TableBody>
                                  </Table>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                        ))}
                      </TableBody>
                  </Table>
              </TableContainer>
            </Col>
        </Container>
    )
}
export default Requests ;