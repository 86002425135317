import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useSelector,useDispatch } from 'react-redux';
import {addProduct,setBaket,deleteProduct} from '../../../store';
import {useState,useEffect} from 'react'
import Load from '../../../components/load'
import axios from "axios";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Err401 from '../../../assets/SVGs/err401'
import Err404 from '../../../assets/SVGs/err404'
import Alert from '@mui/material/Alert';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const Baket =()=>{
    const [loading,setLoading]=React.useState(false);
    const acc=useSelector((state) => state.counter.account);
    const Lang=useSelector((state) => state.counter.language);
    const backet=useSelector((state) => state.counter.baket);
    const token = useSelector((state) => state.counter.token);
    const [openDialog, setOpenDialog] = useState(false);
    const [errorQuantity,setErrorQuantity] = React.useState(false);
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const [alignment, setAlignment] = React.useState('web');

    const handleChange = (event, newAlignment) => {
      setAlignment(newAlignment);
    }

    const [selectedDelServ,setSelectedDelServ] = React.useState(null);
    const handleChangeSelectedDelSer =(event)=>{
        setSelectedDelServ(event.target.value);
      }


    const dispatch = useDispatch();
    const [total,setTotal] = useState(0);
    const [delServ,setDelServ] = useState([]);
    var all=0;

    useEffect(() => {
        setTotal(0)
        console.log(backet.length)
        for(var i=0 ; i<backet.length ; i++)
        {
            all=all+(!backet[i][0].offer ? (backet[i][0].quantity * backet[i][0].price) : ( backet[i][0].quantity * (backet[i][0].price-(((backet[i][0].offer)*(backet[i][0].price))/100)) ))
        }
        setTotal(all)
    }, [backet]);

    useEffect(() => {
        setSelectedDelServ(null)
        axios.get("https://rest.istanbulru.com/api/showDelSer",{
            headers:{
                "Accept":"application/json",
                "Authorization":"Bearer "+token
            }
        })
        .then((response) => {
            
            console.log(response.data);
            setDelServ(response.data.data);
        })
        .catch((error) => console.log(error));
    }, []);


    const handleChangeQuantity =(event,data)=>{
        if(data.quantity===1 && event===-1 ) 
            return
        var test=[{
            "id":data.id,
            "name":data.name,
            "imgURL":data.imageURL,
            "disc":data.disc,
            "price":data.price,
            "offer":data.offer,
            "offer_id":data.offer_id,
            "quantity": parseInt(event)
        }];
        dispatch(addProduct(test))
    }
    const handledeleteProduct =(item,i)=>{
        dispatch(deleteProduct(i))
        
    }

    const send_data=()=>{
          
            setLoading(true)
            try {
                const response = axios.post('https://rest.istanbulru.com/api/addRequestFromweb', {
                  del_ser_id:selectedDelServ,
                  cash_payment:alignment,
                  discount_id:null,
                  products:backet
                },{
                    headers:{
                        'Content-Type': 'application/json',
                        'Authorization' : 'Bearer ' +token 
                    }
                }
                ).then((response) => {
                    
                    if(response.data.status===true)
                    {
                        window.location.href = '/requests'
                        dispatch(setBaket());
                        
                    }else
                    {
                      setErrorQuantity(true)
                      setOpenDialog(false)
                      
                    }
                    setLoading(false)
                }).catch((error) => {
                    console.log(error)
                setLoading(false)
                });
            } catch (e) {
                throw e;
            }
        
    }

    if(acc!==1)
    return(
        <>
        <Load run={loading} />
        <Err401 />
            <h4>
                {(Lang === "Ar" ? ("يجب عليك تسجيل الدخول أولا") : Lang === "En" ? ("you have to login first") : " сначала вам нужно войти в систему")}
            </h4>
        </>
    )

    if(!backet.length)
        return(
            <>
            <Load run={loading} />
            <Err404 />
                <h4>
                    {(Lang === "Ar" ? ("يجب عليك إضافة بضائع أولا") : Lang === "En" ? ("You need to add products first") : " Сначала необходимо добавить товары")}
                </h4>
            </>
        )

    return(
        <Container >
            <Load run={loading} />
            <Alert hidden={!errorQuantity} variant="filled" severity="error">
                {Lang==="Ar" ? ("الكمية ") : Lang==="En"? ("Quantity ") : "Количество "}
            </Alert>
            <Row className="justify-content-center" >
                <Col  lg={8} md={10} sm={7} xs={10}>
                    {
                        backet.map((item,index)=>{
                            return(
                                <Row style={{ padding : "0px" }} className=" baket_card" >
                                    <Col  lg={4} md={5} sm={12} style={{ padding : "0px" }}>
                                        <img className={ " baket_img_card" + (Lang==='Ar' ? (" b_r_tr_img  b_r_br_img ") : (" b_r_tl_img b_r_bl_img "))  } src={item[0].imgURL}  />
                                    </Col>
                                    <Col lg={4} md={3} sm={12} className="baket_info_card" > 
                                            <h4> {item[0].name} </h4>
                                            <div className="light_font">{item[0].disc}</div>
                                            <h4 className={"gold-color "+(item[0].offer ? ("d_n") : ("")) } > {item[0].price} ₽</h4>
                                            <h4 className={"App-text "+(item[0].offer ? ("") : ("d_n")) }> offer : {item[0].offer}% </h4>
                                            <h4 className={"gold-color "+(item[0].offer ? ("") : ("d_n")) }>  { item[0].price-(((item[0].offer)*(item[0].price))/100) } ₽ </h4>
                                            <Form > 
                                            <Form.Group>
                                                <Form.Label>
                                                {Lang==="Ar" ? ("الكمية ") : Lang==="En"? ("Quantity ") : "Количество "}:
                                                </Form.Label><br/>
                                                <Button onClick={()=>handleChangeQuantity(-1,item[0])} className="App_button" ><h4> - </h4></Button>

                                                <Form.Control
                                                    
                                                    value={item[0].quantity}
                                                    disabled
                                                    onChange={(e)=>handleChangeQuantity(e,item[0])}
                                                    style={{ maxWidth: "100px" , display : "inline-block" }}
                                                    required
                                                    type="number"
                                                    min={1}
                                                    maxLength="10"
                                                />

                                                <Button onClick={()=>handleChangeQuantity(1,item[0])} className="App_button" ><h4> + </h4></Button>
                                                
                                            </Form.Group>
                                            </Form>
                                    </Col>
                                    <Col className="total" lg={3} md={2} sm={12} xs={12}>
                                        {Lang==="Ar" ? ("المجموع ") : Lang==="En"? ("total ") : "Общая "}:<h4 style={{ display:"inline-block" }} className="App-text">{!item[0].offer ? (item[0].quantity * item[0].price) : ( item[0].quantity * (item[0].price-(((item[0].offer)*(item[0].price))/100)) )}₽</h4><br/>
                                        <Button onClick={()=>handledeleteProduct(item[0],index)} className="App_button" >  {Lang==="Ar" ? (" حذف ") : Lang==="En"? ("delete") : "удалить"}</Button>
                                    </Col>
                                </Row>
                            )
                        })
                    }
                </Col>
                
                <Col style={{ height:"100%" , display:"grid" }} className="baket_card" lg={3} md={7} sm={8} xs={10}>
                    <div >
                        {
                            

                            backet.map((item)=>{
                                if(item[0].quantity>0)
                                return(
                                    <>
                                    <span>{item[0].name} : </span><h5 className="App-text end_card_baket" >{!item[0].offer ? (item[0].quantity * item[0].price) : ( item[0].quantity * (item[0].price-(((item[0].offer)*(item[0].price))/100)) )} ₽</h5><br/>
                                    </>
                                )
                            })
                        }

                    </div><br/>
                    <div>
                    {Lang==="Ar" ? ("المجموع ") : Lang==="En"? ("total ") : "Общая "} : <h4 className="gold-color end_card_baket" >{total} ₽ </h4>
                    </div>
                    <br/>
                    <Button style={{     width: "200px" , margin: "auto" }} onClick={()=>setOpenDialog(true)} className="App_button"> {Lang==="Ar" ? ("الانتقال إلى الدفع ") : Lang==="En"? ("Continue to payment ") : "Перейти к оплате "} <ArrowForwardIcon/> </Button>
                </Col>
            </Row>
            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDialog}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className="App-text" style={{ textAlign: "center" }}  >{Lang==="Ar" ? ("إرسال طلب شراء ") : Lang==="En"? (" Submit a purchase order ") : " Отправьте заказ на покупку "}</DialogTitle>
                <DialogContent>
                <DialogContentText style={{ textAlign: "center" }}  id="alert-dialog-slide-description"><br/>
                    <div style={{ textAlign: "center" }} >{Lang==="Ar" ? ("اختر طريقة للدفع") : Lang==="En"? (" Choose a method of payment ") : " Выберите способ оплаты"}</div><br/>
                    <ToggleButtonGroup
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                        >
                        <ToggleButton  style={{ display: "block" }} className="payment_button" value={1}>
                        <div style={{ textAlign: "center" }} >{Lang==="Ar" ? ("الدفع كاش") : Lang==="En"? (" Cash payment") : " Наличный расчет"}</div>
                            <svg height="120px" width="120px" version="1.1" id="Layer_1"  
                                viewBox="0 0 512 512" >
                            <path style={{ fill:"#FFD890" }} d="M501.801,461.431H57.213c-25.965,0-47.014-21.049-47.014-47.014V188.546
                                c0-25.965,21.049-47.011,47.014-47.011h444.587V461.431z"/>
                            <path style={{ fill:"#FF9839" }} d="M501.801,346.041h-92.394c-24.61,0-44.559-19.95-44.559-44.559l0,0
                                c0-24.61,19.95-44.559,44.559-44.559h92.394V346.041z"/>
                            <circle style={{ fill:"#947C7C" }} cx="409.814" cy="301.479" r="17.379"/>
                            <polygon style={{ fill:"#FFD890" }} points="419.481,50.57 362.388,67.371 435.08,104.921 "/>
                            <polygon style={{ fill:"#BDD169" }} points="362.388,67.371 110.359,141.535 445.589,141.535 435.08,104.921 "/>
                            <g>
                                <path style={{ fill:"#4C1D1D" }} d="M108.89,131.336H57.213C25.665,131.336,0,157,0,188.546v225.871
                                    c0,31.547,25.665,57.213,57.213,57.213h444.587c5.632,0,10.199-4.566,10.199-10.199V141.535c0-5.633-4.567-10.199-10.199-10.199
                                    h-48.528l-23.989-83.579c-0.75-2.611-2.51-4.816-4.892-6.125s-5.184-1.615-7.792-0.847L108.89,131.336z M418.754,85.007
                                    l-9.362-4.835l-19.734-10.193l22.852-6.725L418.754,85.007z M361.341,78.311L391.9,94.096l34.59,17.867l5.561,19.372H181.148
                                    L361.341,78.311z M491.602,151.734v94.989h-82.194c-30.194,0-54.758,24.565-54.758,54.758s24.565,54.759,54.758,54.759h82.194
                                    v94.991H57.213c-20.3,0-36.815-16.515-36.815-36.815V188.546c0-20.298,16.515-36.812,36.815-36.812H491.602z M491.602,335.842
                                    h-82.194c-18.946,0-34.36-15.414-34.36-34.36c0-18.946,15.414-34.36,34.36-34.36h82.194V335.842z"/>
                                <path style={{ fill:"#4C1D1D" }} d="M382.243,301.481c0,15.205,12.37,27.575,27.574,27.575c15.205,0,27.575-12.37,27.575-27.575
                                    c0-15.204-12.37-27.574-27.575-27.574C394.612,273.907,382.243,286.277,382.243,301.481z M409.817,294.306
                                    c3.957,0,7.176,3.219,7.176,7.175s-3.219,7.176-7.176,7.176c-3.956,0-7.175-3.219-7.175-7.176
                                    C402.642,297.524,405.861,294.306,409.817,294.306z"/>
                                <path style={{ fill:"#4C1D1D" }} d="M270.819,418.527h-7.155c-5.632,0-10.199,4.566-10.199,10.199s4.567,10.199,10.199,10.199h7.155
                                    c5.632,0,10.199-4.566,10.199-10.199S276.452,418.527,270.819,418.527z"/>
                                <path style={{ fill:"#4C1D1D" }} d="M468.074,418.527H314.767c-5.632,0-10.199,4.566-10.199,10.199s4.567,10.199,10.199,10.199h153.307
                                    c5.632,0,10.199-4.566,10.199-10.199S473.706,418.527,468.074,418.527z"/>
                            </g>
                            </svg>
                        </ToggleButton>
                        <ToggleButton style={{ display: "block" }} className="payment_button" value={0}><br/>
                        <div style={{ textAlign: "center" }} >{Lang==="Ar" ? ("دفع بنكي ") : Lang==="En"? (" Bank payment ") : " Банковский платеж"}</div>
                            <svg height="120px" width="120px" version="1.1"  id="Layer_1" viewBox="0 0 512 512">
                            <polygon style={{ fill:"#BDD169" }} points="451.399,277.563 501.801,227.162 501.801,119.639 451.399,69.237 181.653,69.237 
                                131.252,119.639 131.252,227.162 181.653,277.563 "/>
                            <circle style={{ fill:"#E6E6E6"  }} cx="316.522" cy="173.397" r="56.786"/>
                            <g>
                                <polygon style={{ fill:"#FFD890" }} points="501.801,277.563 501.801,227.162 451.399,277.563 	"/>
                                <polygon style={{ fill:"#FFD890" }} points="451.399,69.237 501.801,119.639 501.801,69.237 	"/>
                                <polygon style={{ fill:"#FFD890" }} points="131.252,277.563 131.252,227.162 181.654,277.563 	"/>
                                <polygon style={{ fill:"#FFD890" }} points="181.654,69.237 131.252,119.639 131.252,69.237 	"/>
                                <rect x="10.199" y="277.561" style={{ fill:"#FFD890" }} width="278.887" height="165.197"/>
                            </g>
                            <rect x="10.199" y="370.823" style={{ fill:"#947C7C" }} width="278.887" height="35.527"/>
                            <g>
                                <path style={{ fill:"#4C1D1D" }} d="M316.526,106.415c-36.935,0-66.984,30.049-66.984,66.985c0,36.935,30.049,66.984,66.984,66.984
                                    s66.985-30.049,66.985-66.984C383.511,136.464,353.462,106.415,316.526,106.415z M316.526,219.987
                                    c-25.688,0-46.586-20.898-46.586-46.586s20.898-46.587,46.586-46.587c25.689,0,46.587,20.899,46.587,46.587
                                    S342.214,219.987,316.526,219.987z"/>
                                <path style={{ fill:"#4C1D1D" }} d="M320.538,165.002v-13.104c5.834,0.449,7.539,3.321,10.501,3.321c3.949,0,5.565-4.936,5.565-7.36
                                    c0-6.193-10.501-7.628-16.066-7.809v-2.244c0-1.257-1.705-2.423-3.411-2.423c-1.975,0-3.411,1.167-3.411,2.423v2.513
                                    c-9.514,1.346-17.951,6.821-17.951,18.219c0,11.488,9.693,15.348,17.951,18.489v14.539c-6.642-1.167-9.693-6.462-13.463-6.462
                                    c-3.411,0-6.102,4.488-6.102,7.539c0,5.744,8.796,11.308,19.566,11.667v2.244c0,1.257,1.436,2.423,3.411,2.423
                                    c1.705,0,3.411-1.167,3.411-2.423v-2.603c10.501-1.705,17.681-8.436,17.681-19.566
                                    C338.219,172.272,328.705,168.053,320.538,165.002z M314.615,162.848c-3.411-1.436-5.744-3.052-5.744-5.654
                                    c0-2.154,1.705-4.218,5.744-5.026V162.848z M319.64,191.479v-12.027c3.231,1.526,5.475,3.411,5.475,6.372
                                    C325.116,189.056,322.693,190.761,319.64,191.479z"/>
                                <path style={{ fill:"#4C1D1D" }} d="M512,69.237c0-5.633-4.567-10.199-10.199-10.199H131.252c-5.633,0-10.199,4.566-10.199,10.199
                                    v198.127H10.199C4.566,267.364,0,271.93,0,277.563v165.2c0,5.633,4.566,10.199,10.199,10.199h278.885
                                    c5.632,0,10.199-4.566,10.199-10.199v-155h202.517c5.632,0,10.199-4.566,10.199-10.199V69.237z M491.602,95.016l-15.579-15.579
                                    h15.579V95.016z M185.878,267.364l-44.428-44.428v-99.073l44.428-44.428h261.295l44.428,44.428v99.073l-44.428,44.428H185.878z
                                    M157.03,79.437l-7.789,7.79l-7.79,7.79v-15.58H157.03z M141.451,251.786l15.578,15.578h-15.578V251.786z M278.885,396.148H20.398
                                    V381.02h258.487V396.148z M20.398,432.564v-16.017h258.487v16.017H20.398z M278.885,287.762v72.86H20.398v-72.86L278.885,287.762
                                    L278.885,287.762z M476.023,267.364l15.578-15.578v15.578H476.023z"/>
                                <path style={{ fill:"#4C1D1D" }} d="M465.084,166.127h3.668c5.632,0,10.199-4.566,10.199-10.199c0-5.633-4.567-10.199-10.199-10.199
                                    h-3.668c-5.632,0-10.199,4.566-10.199,10.199C454.884,161.56,459.452,166.127,465.084,166.127z"/>
                                <path style={{ fill:"#4C1D1D" }} d="M406.926,166.127h28.224c5.632,0,10.199-4.566,10.199-10.199c0-5.633-4.567-10.199-10.199-10.199
                                    h-28.224c-5.632,0-10.199,4.566-10.199,10.199C396.727,161.56,401.293,166.127,406.926,166.127z"/>
                                <path style={{ fill:"#4C1D1D" }} d="M468.751,180.674h-61.826c-5.632,0-10.199,4.566-10.199,10.199s4.567,10.199,10.199,10.199h61.826
                                    c5.632,0,10.199-4.566,10.199-10.199S474.383,180.674,468.751,180.674z"/>
                                <path style={{ fill:"#4C1D1D" }} d="M227.834,145.728h-61.827c-5.633,0-10.199,4.566-10.199,10.199c0,5.633,4.566,10.199,10.199,10.199
                                    h61.827c5.633,0,10.199-4.566,10.199-10.199C238.033,150.294,233.467,145.728,227.834,145.728z"/>
                                <path style={{ fill:"#4C1D1D" }} d="M227.834,180.674h-61.827c-5.633,0-10.199,4.566-10.199,10.199s4.566,10.199,10.199,10.199h61.827
                                    c5.633,0,10.199-4.566,10.199-10.199S233.467,180.674,227.834,180.674z"/>
                                <path style={{ fill:"#4C1D1D" }} d="M99.016,314.437H54.608c-5.633,0-10.199,4.566-10.199,10.199s4.566,10.199,10.199,10.199h44.408
                                    c5.633,0,10.199-4.566,10.199-10.199S104.649,314.437,99.016,314.437z"/>
                            </g>
                            </svg>
                        </ToggleButton>
                    </ToggleButtonGroup>
                        <br/>
                        <br/>
                    
                    <div style={{ textAlign: "center" }} >{Lang==="Ar" ? ("اختر طريقة التوصيل") : Lang==="En"? (" Choose the delivery method  ") : " Выберите способ доставки "}</div>
                    <div style={{ textAlign: "center" }} >{Lang==="Ar" ? ("يمكنك الاطلاع على اسعار خدمات التوصيل") : Lang==="En"? (" You can view the prices of delivery services  ") : "Вы можете посмотреть цены на услуги доставки"}</div><br/>
                    
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel style={{ color:"#E6392B" }} id="demo-simple-select-standard-label">{Lang==="Ar" ? ("خدمات التوصيل ") : Lang==="En"? (" Delivery Service ") : " Служба доставки "}  </InputLabel>
                            <Select
                                style={{ borderColor:"#E6392B" , width:"200px" }} 
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                onChange={handleChangeSelectedDelSer}
                                label="Delivery Service"
                                >
                                    <MenuItem value={null}>no delivery _ free</MenuItem>
                                {
                                    delServ.map((item)=>{
                                        return(
                                            <MenuItem value={item.id}>{item.city} _ {item.price}₽</MenuItem>
                                        )
                                    })
                                }
                        </Select>
                    </FormControl>



                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>send_data()} className="App_button" >{Lang==="Ar" ? ("متابعة ") : Lang==="En"? (" continuation") : " продолжение "}</Button>
                </DialogActions>
            </Dialog>
            
        </Container>
    )
}
export default Baket