import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useSelector,useDispatch } from 'react-redux';
import {addProduct,setBaket} from '../store';
import axios from "axios";
import Load from '../components/load';
import Button from 'react-bootstrap/Button';
import TextField from '@mui/material/TextField';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

  

import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


  function RowInTable(props) {
    
    const [open, setOpen] = React.useState(false);
    
    const Lang=useSelector((state) => state.counter.language);
    const token = useSelector((state) => state.counter.token);
    const [openChangeDialog, setOpenChangeDialog] = React.useState(false);
    const [loading,setLoading]=React.useState(false);
    const handleClickOpenChangeDialog = () => {
      setOpenChangeDialog(true);
  };
  const handleCloseChangeDialog = () => {
      setOpenChangeDialog(false);
  };

    const changeState=(req_id,state_id)=>{
      setLoading(true)
      try {
        const response = axios.post('https://rest.istanbulru.com/api/editReqState', {
          request_id:req_id,
          state_id:state_id,
        },{
            headers:{
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' +token 
            }
        }
        ).then((response) => {
            console.log(response.data);
            
            setLoading(false)
        }).catch((error) => {
            console.log(error)
            setLoading(false)
        });
    } catch (e) {
        throw e;
    }
    }

    return (
      <React.Fragment>
        <Load run={loading} />
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {props.row.created_at}
          </TableCell>
          <TableCell align="start">{props.row.request_state}</TableCell>
          <TableCell align="start">{props.row.total_quantity}</TableCell>
          <TableCell align="start">{props.row.total_price}</TableCell>
          <TableCell align="start">{props.row.delivery_ser_city_name ? (props.row.delivery_ser_city_name+" _ "+props.row.delivery_ser_price+"₽") : (Lang==="Ar" ? ("بدون توصيل ") : Lang==="En"? (" Without delivery ") : " Без доставки ")}</TableCell>
          <TableCell align="start"><Button onClick={handleClickOpenChangeDialog}  variant="outline-danger" className="keyword_button"  >{Lang === "Ar" ? (" بيانات العميل ") : Lang === "En" ? ("client data ") : "данные клиента"}</Button></TableCell>
          <TableCell align="start" >
            { props.row.state_id===1 ?  ( <Button onClick={()=>changeState( props.row.id , 2)} variant="outline-danger" className="keyword_button"  > {Lang==="Ar" ? (" بدء العمل ") : Lang==="En"? (" Start working ") : " Начать работать "}</Button> ) : 
              props.row.state_id===2 ? (<Button onClick={()=>changeState(props.row.id,3)} variant="outline-danger" className="keyword_button"  > {Lang==="Ar" ? (" إنهاء العمل ") : Lang==="En"? (" End working ") : " Завершить работу "} </Button>) : 
              ("")
            }
             </TableCell>
          {/*<TableCell align="start"><Button onClick={()=>changeState(props.row.id,4)} variant="outline-danger" className="keyword_button"  > cancellation </Button></TableCell>*/}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                {Lang==="Ar" ? ("تفاصيل الطلب") : Lang==="En"? (" Order details ") : " Информация для заказа "} 
                </Typography>
                <Table style={{ minWidth: "400px" }} size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>  {Lang==="Ar" ? ("اسم العنصر ") : Lang==="En"? (" name ") : " имя "} </TableCell>
                      <TableCell align="start"> code </TableCell>
                      <TableCell> {Lang==="Ar" ? ("السعر ") : Lang==="En"? (" price ") : " Цена "} </TableCell>
                      <TableCell align="start"> {Lang==="Ar" ? (" الكمية ") : Lang==="En"? (" quantity ") : " Количество "}  </TableCell>
                      <TableCell align="start"> {Lang==="Ar" ? (" نسبة العرض ") : Lang==="En"? (" offer percentage ") : " процент предложения "}  </TableCell>
                      <TableCell align="start"> {Lang==="Ar" ? (" المجموع ") : Lang==="En"? (" Total price ") : " Итоговая цена "}</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody >
                                          {props.row.products.map((historyRow) => (
                                          <TableRow  key={historyRow.name}>
                                              <TableCell component="th" scope="row">
                                              {historyRow.name}
                                              </TableCell>
                                              <TableCell>{historyRow.code}</TableCell>
                                              <TableCell>{historyRow.price}₽</TableCell>
                                              <TableCell >{historyRow.required_quantity}</TableCell>
                                              <TableCell >{  historyRow.offer_percentage ? (historyRow.offer_percentage +"%") : (Lang==="Ar" ? (" بدون عرض ") : Lang==="En"? (" No offer ") : " Нет предложения ") }</TableCell>
                                              <TableCell align="start">
                                                {historyRow.offer_percentage ?  ((historyRow.price-(historyRow.price*historyRow.offer_percentage/100))*historyRow.required_quantity) : (historyRow.required_quantity * historyRow.price)}₽
                                              </TableCell>
                                          </TableRow>
                                          ))}
                                      </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
        <Dialog
                open={openChangeDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseChangeDialog}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{Lang === "Ar" ? (" بيانات العميل ") : Lang === "En" ? ("client data ") : "данные клиента"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <div>
                      name : {props.row.user_name}
                    </div>
                    <div>
                      email : {props.row.user_email}
                    </div>
                    <div>
                      phone numper {props.row.user_number}
                    </div>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button className="App_button" onClick={handleCloseChangeDialog}>{Lang === "Ar" ? (" إلغاء  ") : Lang === "En" ? ("console") : "Отменить "}</Button>
                </DialogActions>
            </Dialog>
      </React.Fragment>
    );
  }

  


const AdminRequests =()=>{
        const dispatch = useDispatch();
        const Lang=useSelector((state) => state.counter.language);
        const token = useSelector((state) => state.counter.token);
        const backet=useSelector((state) => state.counter.baket);
        const [loading,setLoading]=React.useState(false);
        const [allProduct, setAllProduct] = React.useState([]);
        const [delServ,setDelServ] = React.useState([]);
        const [selectedDelServ,setSelectedDelServ] = React.useState(0);
        const [product, setProduct] = React.useState({});
        const [quantity, setQuantity] = React.useState(1);
        const [salary, setSalary] = React.useState(0);
        const [data, setData] = React.useState([]);

        const [errorQuantity,setErrorQuantity] = React.useState(false);
        const [open, setOpen] = React.useState(false);
        const handleChangeProduct = (event) => {
            setProduct(event.target.value)
            console.log(event.target.value)
        }
    
        const handleChangeQuantity = (event) => {
            setQuantity(event.target.value)
        }
        const handleChangeSelectedDelSer =(event)=>{
          setSelectedDelServ(event.target.value);
        }



        const Add_To_Bacet=()=>{
          if(product && quantity && quantity>0)
          dispatch(addProduct(
            [{
                "id":product.id,
                "name":product.name,
                "imgURL":product.imageURL,
                "disc":product.disc,
                "price":product.price,
                "offer":product.offer,
                "offer_id":product.offer_id,
                "quantity":parseInt(quantity)
            }]
        ));
        }
        const Add_Request=()=>{
          if(backet!==[])
          {
            setLoading(true)
            try {
                const response = axios.post('https://rest.istanbulru.com/api/addRequestFromweb', {
                  del_ser_id:selectedDelServ,
                  cash_payment:1,
                  discount_id:null,
                  products:backet
                },{
                    headers:{
                        'Content-Type': 'application/json',
                        'Authorization' : 'Bearer ' +token 
                    }
                }
                ).then((response) => {
                    if(response.status===true)
                    {
                      console.log(response.data);
                      setData(response.data.requests)
                      dispatch(setBaket());
                      setOpen(false);
                    }else
                    {
                      setOpen(true)
                    }
                    setLoading(false)
                }).catch((error) => {
                    console.log(error)
                setLoading(false)
                });
            } catch (e) {
                throw e;
            }
          }
        }

        React.useEffect(() => {
          setLoading(true);
          axios.get("https://rest.istanbulru.com/api/showAllRequests",{
          headers:{
              "Accept":"application/json",
              "Authorization":"Bearer "+token
          }
        })
          .then((response) => {
            console.log(response.data)
            setData(response.data.requests)
        })
          .catch((error) => console.log(error));
          ////////////////////////
          axios.get("https://rest.istanbulru.com/api/showDelSer",{
            headers:{
                "Accept":"application/json",
                "Authorization":"Bearer "+token
            }
          })
            .then((response) => {
              setDelServ(response.data.data);
          })
            .catch((error) => console.log(error));
            //
          axios.get("https://rest.istanbulru.com/api/showProducts")
              .then((response) => {
                  setAllProduct(response.data.products)
                  console.log(response.data)
                  setLoading(false);
              })
              .catch((error) => {
                  console.log(error)
                  setLoading(false);
              });
      }, []);


    /*React.useEffect(() => {
      setTimeout(() => {
        axios.get("https://rest.istanbulru.com/api/showAllRequests",{
          headers:{
              "Accept":"application/json",
              "Authorization":"Bearer "+token
          }
        })
          .then((response) => {
            console.log(response.data)
            setData(response.data.requests)
        })
          .catch((error) => console.log(error));
      }, []);
    });*/
  


    const handleClick = () => {
      setOpen(true);
    };
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
    const action = (
      <React.Fragment>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );

        return(
            <Container>
              <Load run={loading} />
                <Row className="justify-content-center" >
                    <Col style={{ margin: "30px 0px" }} className="input_item_admin" lg={3} md={4} sm={12}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">product</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={product}
                                label="product"
                                onChange={handleChangeProduct}
                            >
                            {
                                allProduct.map((item)=>{
                                    if(item.quantity>0)
                                    return(
                                        <MenuItem value={item}>{item.name}</MenuItem>
                                    )
                                })
                            }
                            </Select>
                        </FormControl>
                    </Col>
                    <Col style={{ margin: "30px 0px" }} className="input_item_admin" lg={3} md={4} sm={12}>
                        <TextField onChange={handleChangeQuantity} value={quantity} style={{ width: "100%" }} id="outlined-number" type="number" label="numper of product" variant="outlined" />
                    </Col>
                    <Col style={{ margin: "30px 0px" }} className="input_item_admin" lg={2} md={3} sm={10}>
                        <Button onClick={()=>Add_To_Bacet()} className="App_button">{Lang === "Ar" ? ("إضافة إلى الطلب") : Lang === "En" ? ("Add to request") : "Начать покупки"}</Button>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col className="input_item_admin">
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                            <TableCell>  {Lang==="Ar" ? ("اسم العنصر ") : Lang==="En"? (" name ") : " имя "} </TableCell>
                            <TableCell> {Lang==="Ar" ? ("السعر ") : Lang==="En"? (" price ") : " Цена "} </TableCell>
                            <TableCell align="start"> {Lang==="Ar" ? (" الكمية ") : Lang==="En"? (" quantity ") : " Количество "}  </TableCell>
                            <TableCell align="start"> {Lang==="Ar" ? (" المجموع ") : Lang==="En"? (" Total price ") : " Итоговая цена "}</TableCell>
                           
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                
                              {
                                backet.map((item)=>{
                                  return(
                                    <TableRow>
                                      <TableCell component="th" scope="row">
                                      {item[0].name}
                                      </TableCell>
                                      <TableCell>{ item[0].price }₽</TableCell>
                                      <TableCell align="start"> {item[0].quantity} </TableCell>
                                      <TableCell align="start">
                                      {item[0].price * item[0].quantity}₽
                                      </TableCell>
                                      
                                    </TableRow>
                                  )
                                })
                              }
                              <TableRow>
                                <Button onClick={()=>dispatch(setBaket())} variant="outline-danger" className="keyword_button"  ><h5>{Lang==="Ar" ? (" إزالة ") : Lang==="En"? (" remove ") : " удалять "}</h5></Button>
                              </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                  
                    <Col style={{ margin: "30px 0px" }}  lg={3} md={4} sm={12}>
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                              <InputLabel style={{ color:"#E6392B" }} id="demo-simple-select-standard-label">{Lang==="Ar" ? ("خدمات التوصيل ") : Lang==="En"? (" Delivery Service ") : " Служба доставки "}  </InputLabel>
                              <Select
                                  style={{ borderColor:"#E6392B" , width:"200px" }} 
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  onChange={handleChangeSelectedDelSer}
                                  label="Delivery Service"
                                  >
                                    <MenuItem value={null}>no delivery _ free</MenuItem>
                                  {
                                      delServ.map((item)=>{
                                          return(
                                              <MenuItem value={item.id}>{item.city} _ {item.price}₽</MenuItem>
                                          )
                                      })
                                  }
                          </Select>
                      </FormControl>
                    </Col>
                    <Col lg={3} md={4} sm={12} style={{ margin: "30px 0px" }} >
                        <Button onClick={()=>Add_Request()} className="App_button"><h5>{Lang === "Ar" ? ("حفظ البيانات") : Lang === "En" ? ("save data") : "Начать покупки"}</h5></Button>
                    </Col>
                </Row>


                <Row className="justify-content-center">
                    <Col className="input_item_admin" l>
                    
                    
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell> {Lang==="Ar" ? (" تاريخ الطلب ") : Lang==="En"? (" date ") : "Дата подачи заявления"} </TableCell>
                                    <TableCell align="start"> {Lang==="Ar" ? (" حالة الطلب ") : Lang==="En"? (" state ") : "Статус заказа"} </TableCell>
                                    <TableCell align="start">  {Lang==="Ar" ? (" عدد العناصر ") : Lang==="En"? (" total ") : "Количество предметов"} </TableCell>
                                    <TableCell align="start"> {Lang==="Ar" ? ("  سعر الطلب ") : Lang==="En"? (" price ") : "Спроси цену"} </TableCell>
                                    <TableCell align="start"> {Lang==="Ar" ? (" حالة التوصيل ") : Lang==="En"? (" delivery ") : "Возможность доставки"} </TableCell>
                                    <TableCell align="start"> {Lang === "Ar" ? (" بيانات العميل ") : Lang === "En" ? ("client data ") : "данные клиента"}</TableCell>
                                    <TableCell align="start"> {Lang==="Ar" ? (" حالة الطلب ") : Lang==="En"? (" request state") : "состояние запроса"} </TableCell>
                                    {/*<TableCell align="start"> cancellation </TableCell>*/}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {data.map((row) => (
                                <RowInTable row={row} />
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Col>
            </Row>
            <div>
              <Snackbar
                open={open}
                autoHideDuration={5000}
                message={Lang === "Ar" ? (" كمية البضائع المختارة غير متوفرة في المتجر حالياً ,يرجى تقليل الكمية  ") : Lang === "En" ? ("The selected quantity of goods is currently not available in the store,Please reduce the quantity") : "Выбранного количества товара на данный момент нет в наличии в магазине,Пожалуйста, уменьшите количество"}
                action={action}
              />
            </div>
        </Container>
    )
}
export default AdminRequests