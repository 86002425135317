//import Err404 from '../../../assets/SVGs/err404'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSelector } from 'react-redux';

const Aboutus = () => {
    const Lang = useSelector((state) => state.counter.language);
    return(
        <Container>
            <Row className="justify-content-center" >
                <Col style={{ margin: "30px 0px" }} lg={12} sm ={12}>
                <h1 className="about_us_head"> <span className="App-text">{Lang === "Ar" ? ("إسطنبول") : Lang === "En" ? ("Istanbul") : "Стамбул"}</span> <span className="gold-color i_font about_us_head2">{Lang === "Ar" ? ("شوب") : Lang === "En" ? ("shop") : "магазин"}</span>  </h1>
                </Col><br/>
                <Col  style={{ textAlign:"center" }} lg={6} md={9} sm ={12}>
                {Lang === "Ar" ? 
                ("أفضل متجر إلكتروني لبيع المنتجات التركية في روسيا الاتحادية بجودة عالية و ضمان ممتاز بما يوفر وصول سهل للبضائع و طرق دفع سلسة و خدمات توصيل متعددة") : Lang === "En" ? 
                ("The best online store for selling Turkish products in the Russian Federation with high quality and excellent warranty, providing easy access to goods, smooth payment methods, and multiple delivery services.") : 
                ("Лучший интернет-магазин по продаже турецких товаров в Российской Федерации с высоким качеством и отличной гарантией, обеспечивающий легкий доступ к товарам, удобные способы оплаты и разнообразные службы доставки.")}
                </Col><br/><br/><br/>
                <Col   lg={12} sm ={12}>
                <br/><br/><br/><br/>
                   <h1 className="about_us_head3"> {Lang === "Ar" ? ("فروع متاجرنا") : Lang === "En" ? ("STORE BRANCHES") : "ФИЛИАЛЫ МАГАЗИНА"}</h1><br/><br/><br/>
                </Col>
            </Row>
            <Row style={{ margin: "60px 0px" }} >
                <Col lg={8} md={6} sm ={12}>
                    <iframe className="maps" src="https://yandex.ru/map-widget/v1/-/CDFiRPn6~8B"></iframe>
                </Col>
                <Col className="map_text" lg={4} md={6} sm ={12}>
                Восточные сладости Стамбул улица Ставропольская, 218/1
                </Col>
            </Row>
            <div style={{ display: "flex" , justifyContent: "center" }}>
                <hr style={{  width: "84%" }} />
            </div>
            <Row style={{ margin: "60px 0px" }} >
                <Col className="map_text" lg={4} md={6} sm ={12}>
                
                Стамбул улица 1 Мая, 101
                </Col>
                <Col lg={8} md={6} sm ={12}>
                    <iframe className="maps" src="https://yandex.ru/map-widget/v1/-/CDFi6SIm~8B"></iframe>
                </Col>

            </Row>
            <div style={{ display: "flex" , justifyContent: "center" }}>
                <hr style={{     width: "84%" }} />
            </div>
            <Row style={{ margin: "60px 0px" }} >
                <Col lg={8} md={6} sm ={12}>
                    <iframe className="maps" src="https://yandex.ru/map-widget/v1/-/CDFi68-7~8B"></iframe>
                </Col>
                <Col className="map_text" lg={4} md={6} sm ={12}>

                Стамбул Офицерская улица, 34
                </Col>
            </Row>
            <div style={{ display: "flex" , justifyContent: "center" }}>
                <hr style={{     width: "84%" }} />
            </div>

            <Row style={{ margin: "60px 0px" }} >
                <Col className="map_text"  lg={4} md={6} sm ={12}>

                Ставропольская улица, 220/1
                Краснодар
                </Col>
                <Col lg={8} md={6} sm ={12}>
                    <iframe className="maps" src="https://yandex.ru/map-widget/v1/-/CDFi6P2j~8B"></iframe>
                </Col>

            </Row>
            
        </Container>
    )
}
export default  Aboutus