import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import axios from "axios";
import Load from '../components/load';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const Sales =()=>{
    const Lang = useSelector((state) => state.counter.language);
    const token = useSelector((state) => state.counter.token);
    const [openBlockDialog, setOpenBlockDialog] = React.useState(false);
    const [openChangeDialog, setOpenChangeDialog] = React.useState(false);
    const [loading,setLoading]=React.useState(false);

    const handleClickOpenBlockDialog = () => {
        setOpenBlockDialog(true);
    };
    const handleCloseBlockDialog = () => {
        setOpenBlockDialog(false);
    };
    const handleClickOpenChangeDialog = () => {
        setOpenChangeDialog(true);
    };
    const handleCloseChangeDialog = () => {
        setOpenChangeDialog(false);
    };
    const [allProduct, setAllProduct] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [product, setProduct] = React.useState('');
    const [quantity, setQuantity] = React.useState(1);
    const [salary, setSalary] = React.useState(0);

    const [errdProduct, setErrProduct] = React.useState(false);
    const [errdQuantity, setErrQuantity] = React.useState(false);
    const [errSalary, setErrSalary] = React.useState(false);

    const [changedProduct, setChangedProduct] = React.useState('');
    const [changedQuantity, setChangedQuantity] = React.useState(1);
    const [changedSalary, setChangedSalary] = React.useState(0);

    const handleChangeProduct = (event) => {
        setProduct(event.target.value)
    }

    const handleChangeSalary = (event) => {
        setSalary(event.target.value)
    }

    const handleChangeQuantity = (event) => {
        setQuantity(event.target.value)
    }

    const handleChangeChangedSalary = (event) => {
        setChangedSalary(event.target.value)
    }

    const handleChangeChangedQuantity = (event) => {
        setChangedQuantity(event.target.value)
    }


    React.useEffect(() => {
        setLoading(true);
        axios.get("https://rest.istanbulru.com/api/showSales",{
            headers:{
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' +token 
            }
        })
        .then((response) => {
            setData(response.data.sales)
            console.log(response.data)
            setLoading(false);
        })
        .catch((error) => {
            console.log(error)
            setLoading(false);
        });

        axios.get("https://rest.istanbulru.com/api/showProducts")
            .then((response) => {
                setAllProduct(response.data.products)
                console.log(response.data)
                setLoading(false);
            })
            .catch((error) => {
                console.log(error)
                setLoading(false);
            });
        
    }, []);

    
    const add_data=()=>{
        if(!product)
            setErrProduct(true)
        else
            setErrProduct(false)

        if( !quantity || quantity<1 )
            setErrQuantity(true)
        else
            setErrQuantity(false)

        if( !salary || salary<0 )
            setErrSalary(true)
        else
            setErrSalary(false)

        if(product && salary && salary>=0 && quantity && quantity>0  )
        {
            setLoading(true)
            try {
                const response = axios.post('https://rest.istanbulru.com/api/addSale', {
                    product_id:product,
                    quantity:quantity,
                    price:salary
                },{
                    headers:{
                        'Content-Type': 'application/json',
                        'Authorization' : 'Bearer ' +token 
                    }
                }
                ).then((response) => {
                    console.log(response.data);
                    setData(response.data.sales);
                    setLoading(false);
                }).catch((error) => {
                    console.log(error);
                    setLoading(true);
                });
            } catch (e) {
                throw e;
            }
        }
    }

    const handleChange=(data)=>{
        setChangedProduct(data.id)
        setOpenChangeDialog(true);
        setChangedQuantity(data.sale_quantity)
        setChangedSalary(data.sale_price)
    }
    const change_data=()=>{
        if( changedQuantity>0 && changedSalary>=0 )
        {
            setLoading(true)
            try {
                const response = axios.post('https://rest.istanbulru.com/api/editSale', {
                    sale_id:changedProduct,
                    quantity:changedQuantity,
                    price:changedSalary
                },{
                    headers:{
                        'Content-Type': 'application/json',
                        'Authorization' : 'Bearer ' +token 
                    }
                }
                ).then((response) => {
                    console.log(response.data);
                    setData(response.data.sales)
                    setLoading(false)
                    setOpenChangeDialog(false)
                }).catch((error) => {
                    console.log(error)
                setLoading(false)
                });
            } catch (e) {
                throw e;
            }
        }
    }

    return(
        <Container>
            <Row className="justify-content-center" >
            <Load run={loading} />
                <Col style={{ margin: "30px 0px" }} className="input_item_admin" lg={3} md={4} sm={12}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{Lang==="Ar" ? ("اسم العنصر ") : Lang==="En"? (" name ") : " имя "}</InputLabel>
                        <Select
                            error={errdProduct}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={product}
                            label={Lang==="Ar" ? ("اسم العنصر ") : Lang==="En"? (" name ") : " имя "}
                            onChange={handleChangeProduct}
                        >
                            {
                                allProduct.map((item)=>{
                                    if(item.quantity>0)
                                    return(
                                        <MenuItem value={item.id}>{item.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>

                </Col>
                <Col error={errSalary} style={{ margin: "30px 0px" }} className="input_item_admin" lg={3} md={4} sm={12}>
                    <TextField onChange={handleChangeSalary} style={{ width: "100%" }} id="outlined-number" type="number" label={Lang === "Ar" ? (" السعر ") : Lang === "En" ? ("salary") : "зарплата"} variant="outlined" />
                </Col>
                <Col error={errdQuantity} style={{ margin: "30px 0px" }} className="input_item_admin" lg={3} md={4} sm={12}>
                    <TextField onChange={handleChangeQuantity} value={quantity} style={{ width: "100%" }} id="outlined-number" type="number" label={Lang==="Ar" ? (" الكمية ") : Lang==="En"? (" quantity ") : " Количество "} variant="outlined" />
                </Col>
                <Col style={{ margin: "30px 0px" }} className="input_item_admin" lg={2} md={3} sm={10}>
                    <Button onClick={()=>add_data()} className="App_button"><h5>{Lang === "Ar" ? ("حفظ البيانات") : Lang === "En" ? ("save data") : "Начать покупки"}</h5></Button>
                </Col>
                
            </Row>
            <Row className="justify-content-center" >
                <Col  >
                <TableContainer  component={Paper}>
                        <Table sx={{ minWidth: 320  }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell style={{ backgroundColor:"#E6392B" }} align="start" >id</StyledTableCell>
                                    <StyledTableCell style={{ backgroundColor:"#E6392B" }} align="start"> {Lang==="Ar" ? ("اسم العنصر ") : Lang==="En"? (" Product name ") : " наименование товара "} </StyledTableCell>
                                    <StyledTableCell style={{ backgroundColor:"#E6392B" }} align="start"> code_id </StyledTableCell>
                                    <StyledTableCell style={{ backgroundColor:"#E6392B" }} align="start"> {Lang === "Ar" ? (" التاريخ ") : Lang === "En" ? ("date") : "дата"} </StyledTableCell>
                                    <StyledTableCell style={{ backgroundColor:"#E6392B" }} align="start"> {Lang === "Ar" ? (" السعر ") : Lang === "En" ? ("salary") : "зарплата"} </StyledTableCell>
                                    <StyledTableCell style={{ backgroundColor:"#E6392B" }} align="start"> {Lang === "Ar" ? (" الكمية ") : Lang === "En" ? (" quantity ") : " Количество "} </StyledTableCell>
                                    <StyledTableCell style={{ backgroundColor:"#E6392B" }} align="start"> {Lang === "Ar" ? (" تعديل ") : Lang === "En" ? ("change") : "изменять"} </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            
                                {
                                    data.map((row)=>{
                                        return(
                                            <StyledTableRow key={"name"}>
                                                <StyledTableCell component="th" scope="row">
                                                    {row.id}
                                                </StyledTableCell>
                                                <StyledTableCell align="start"> {row.product_name} </StyledTableCell>
                                                <StyledTableCell align="start">{ row.code }</StyledTableCell>
                                                <StyledTableCell align="start">{ row.created_at }</StyledTableCell>
                                                <StyledTableCell align="start"> {row.sale_price}₽ </StyledTableCell>
                                                <StyledTableCell align="start"> {row.sale_quantity} </StyledTableCell>
                                                <StyledTableCell align="start"><Button onClick={()=>handleChange(row)}  variant="outline-danger" className="keyword_button"  >{Lang === "Ar" ? (" تعديل ") : Lang === "En" ? ("change") : "изменять"}</Button></StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })
                                }

                                
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Col>
            </Row>
            
            
            {/* change dialog */}
            <Dialog
                open={openChangeDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseChangeDialog}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{Lang === "Ar" ? ("  تعديل العنصر ") : Lang === "En" ? ("change product") : "изменить продукт"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <Col style={{ margin: "30px 0px" }}>
                        <TextField placeholder={changedSalary} onChange={handleChangeChangedSalary} style={{ width: "100%" }} id="outlined-number" type="number" label={Lang === "Ar" ? (" السعر ") : Lang === "En" ? ("salary") : "зарплата"} variant="outlined" />
                    </Col>
                    <Col style={{ margin: "30px 0px" }}>
                        <TextField placeholder={changedQuantity} onChange={handleChangeChangedQuantity} style={{ width: "100%" }} id="outlined-number" type="number" label={Lang === "Ar" ? (" الكمية ") : Lang === "En" ? (" quantity ") : " Количество "} variant="outlined" />
                    </Col>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button className="App_button" onClick={handleCloseChangeDialog}>{Lang === "Ar" ? (" إلغاء التعديل ") : Lang === "En" ? ("console") : "Отменить изменение"}</Button>
                <Button className="App_button" onClick={()=>change_data()}>{Lang === "Ar" ? (" تعديل ") : Lang === "En" ? ("change") : "изменять"}</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}
export default Sales