import { Box, Typography ,IconButton } from '@mui/material'
import React from 'react'
import StaticsCard from '../components/statics_card'
import ProductCarousel from '../components/product_carsoul'
import Button from 'react-bootstrap/Button';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ProductCard from '../components/product_card';

import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import Slide from '@mui/material/Slide';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSelector } from 'react-redux';
import axios from "axios";
import Load from '../../../components/load';

const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
      });

      function createData(name, email, section, phone) {
        return {
                name,
                email,
                section,
                phone,
                };
        }

        const rows = [
                createData('fares', 'f@app.com', 'ITE', +47382940583),
                createData('ahmad', 'a@app.com', 'ITE', +65546456646),
                createData('ghith', 'g@app.com', 'ITE', +65467868678),
        ];


const HomePageAdmin = () => {
        const Lang = useSelector((state) => state.counter.language);
        const token = useSelector((state) => state.counter.token);
        
        const [profits, setProfits] = React.useState(0);
        const [requests_count, setRequests_count] = React.useState(0);
        const [total_delivery, setTotal_delivery] = React.useState(0);
        const [total_quantity_sales, setTotal_quantity_sales] = React.useState('');
        const [total_sales, setTotal_sales] = React.useState('');
        const [newUser, setNewUsers] = React.useState(0);
        const [loading,setLoading]=React.useState(false);
        const [employees,setEmployees]=React.useState([]);
        const [topSales,setTopSales]=React.useState([]);
        const [currentPosition, setCurrentPosition] = React.useState(0);

        const handleNext = () => {
                setCurrentPosition((prevPosition) => prevPosition + 1);
        };

        const handlePrev = () => {
                setCurrentPosition((prevPosition) => Math.max(0, prevPosition - 1));
        };
        const cards = topSales.map((title, index) => <ProductCard key={index} name={title.name} salary={title.price}  title={title.disc} image={title.img_url} />);


        const handleChangeDate=(event)=>{
                
                console.log(event)
                if(event[0] && event[1])
                {
                        setLoading(true);
                        try {
                                const response = axios.post('https://rest.istanbulru.com/api/showStats', {
                                        date1:event[0],
                                        date2:event[1]
                                        },{
                                    headers:{
                                        'Content-Type': 'application/json',
                                        'Authorization' : 'Bearer ' +token 
                                    }
                                }
                                ).then((response) => {
                                    console.log(response.data);
                                    setTopSales(response.data.most_selled_products);
                                    setEmployees(response.data.employees);
                                    setNewUsers(response.data.users_count)
                                    setProfits(response.data.profits)
                                    setRequests_count(response.data.requests_count)
                                    setTotal_quantity_sales(response.data.total_quantity_sales)
                                    setTotal_delivery(response.data.total_delivery)
                                    setTotal_sales(response.data.total_sales)
                                    setLoading(false)
                                }).catch((error) => {
                                    console.log(error)
                                        setLoading(false)
                                });
                            } catch (e) {
                                throw e;
                            }
                }
        }

        const BlockUser =(id)=>{
                setLoading(true);
                axios.get("https://rest.istanbulru.com/api/blockUser/"+id,{
                    headers:{
                        'Content-Type': 'application/json',
                        'Authorization' : 'Bearer ' +token 
                    }
                })
                .then((response) => {
                    console.log(response.data)
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error)
                    setLoading(false);
                });
        }
        return (
                <>
                <Load run={loading} />
                <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        p: 2,
                }}>
                        <Box sx={{
                                display: {
                                        xs: 'block',
                                        sm: 'flex',
                                        md: 'flex',
                                        xl: 'flex'
                                },
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                        }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer  components={['DateRangeCalendar']}>
                                                <DemoItem label={Lang==="Ar" ? (" حدد تاريخ البداية و النهاية للتقرير ") : Lang==="En"? (" Specify the start and end dates for the report ") : " Укажите даты начала и окончания отчета "}>
                                                        <DateRangeCalendar onChange={handleChangeDate} calendars={1} />
                                                </DemoItem>
                                        </DemoContainer>
                                </LocalizationProvider>
                        </Box>
                        <Box sx={{
                                display: {
                                        xs: 'block',
                                        sm: 'flex',
                                        md: 'flex',
                                        xl: 'flex'
                                },
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                        }}>
                                <StaticsCard
                                        title={Lang==="Ar" ? ("المستخدمين الجدد ") : Lang==="En"? (" New users ") : " Новые пользователи "}
                                        value={newUser}
                                />
                                <StaticsCard
                                        title={Lang==="Ar" ? ("مجموع الأرباح ") : Lang==="En"? (" total Profits ") : " общая прибыль "}
                                        value={profits+" ₽"}
                                />
                                <StaticsCard
                                        title={Lang==="Ar" ? ("مجموع الطلبات  ") : Lang==="En"? (" total requests ") : " общее количество запросов "}
                                        value={requests_count}
                                />
                                <StaticsCard
                                        title={Lang==="Ar" ? (" أموال التوصيل ") : Lang==="En"? (" Delivery money ") : "Деньги за доставку"}
                                        value={total_delivery+" ₽"}
                                />
                                <StaticsCard
                                        title={Lang==="Ar" ? ("إجمالي كمية المبيعات ") : Lang==="En"? (" total sales quantity  ") : " общий объем продаж "}
                                        value={total_quantity_sales}
                                />
                                <StaticsCard
                                        title={Lang==="Ar" ? (" مجموع أموال المبيعات  ") : Lang==="En"? (" total sales prices ") : " общие цены продажи "}
                                        value={total_sales+" ₽"}
                                />
                        </Box>
                        <Box sx={{

                        }}>
                                <Typography sx={{
                                        color: '#E6392B',
                                        fontSize: {
                                                xs: 18,
                                                sm: 20,
                                                md: 24,
                                                lg: 28
                                        },
                                        fontFamily: 'Ubuntu',
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        p: 2
                                }}>
                                        Best selling items
                                </Typography>

                                <Box sx={{
                                        display: {
                                                xs: 'block',
                                                sm: 'flex',
                                                md: 'flex',
                                                xl: 'flex'
                                        },
                                        flexWrap: 'wrap',
                                        justifyContent: 'center',
                                }}>
                                        <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', gap: 2, flexWrap: 'wrap' }}>
                                                {/* Product Cards */}
                                                {cards.slice(currentPosition, currentPosition + 4)}

                                                {/* Back Button */}
                                                {currentPosition > 0 && (
                                                        <IconButton onClick={handlePrev} sx={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)' }}>
                                                                <ArrowBackIcon />
                                                        </IconButton>
                                                )}

                                                {/* Forward Button */}
                                                {currentPosition < cards.length - 4 && (
                                                        <IconButton onClick={handleNext} sx={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}>
                                                                <ArrowForwardIcon />
                                                        </IconButton>
                                                )}
                                        </Box>
                                </Box>

                        </Box>
                        <Box sx={{

                        }}>
                                <Typography sx={{
                                        color: '#E6392B',
                                        fontSize: {
                                                xs: 18,
                                                sm: 20,
                                                md: 24,
                                                lg: 28
                                        },
                                        fontFamily: 'Ubuntu',
                                        fontWeight: 'bold',
                                        textAlign: 'end',
                                        p: 2
                                }}>
                                        Employees
                                </Typography>

                                <Box sx={{
                                        display: {
                                                xs: 'block',
                                                sm: 'block',
                                                md: 'flex',
                                                xl: 'flex'
                                        },
                                        flexWrap: 'wrap',
                                        justifyContent: 'center',
                                }}>
                                        <Box sx={{
                        width: '100%',
                        overflowX: 'auto',
                }}>
                        <Paper sx={{ width: '100%', mb: 2 , pr:5 , pl:5 }}>
                                <TableContainer>
                                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                                                <TableHead>
                                                        <TableRow>
                                                                <TableCell>{Lang === "Ar" ? ("اسم الموظف ") : Lang === "En" ? (" name ") : " имя "}</TableCell>
                                                                <TableCell>{Lang === "Ar" ? (" إيميل  ") : Lang === "En" ? (" email ") : " электронная почта "}</TableCell>
                                                                <TableCell>{Lang==="Ar" ? ("رقم الهاتف") : Lang==="En"? ("Phone number") : "номер телефона"}</TableCell>
                                                                <TableCell>{Lang==="Ar" ? (" حظر حساب المستخدم ") : Lang==="En"? ("Block employee account") : "Заблокировать учетную запись пользователя"}</TableCell>
                                                        </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                        {employees.map((row, index) => (
                                                                <TableRow
                                                                        hover
                                                                        role="checkbox"
                                                                        tabIndex={-1}
                                                                        key={index}
                                                                        sx={{ cursor: 'pointer' }}
                                                                >
                                                                        <TableCell>{row.name}</TableCell>
                                                                        <TableCell>{row.email}</TableCell>
                                                                        <TableCell>{row.phone_no}</TableCell>
                                                        <TableCell> <Button onClick={()=>BlockUser(row.id)} variant="outline-danger" className="keyword_button"  >{ row.blocked===0 ? (Lang==="Ar" ? ("حظر") : Lang==="En"? ("block") : "заблокировать ") : (Lang==="Ar" ? ("رفع الحظر") : Lang==="En"? ("Unblock") : "Разблокировать ") }</Button> </TableCell>
                                                                </TableRow>
                                                                ))}
                                                        </TableBody>
                                                </Table>
                                        </TableContainer>
                                </Paper>
                        </Box>
                                </Box>
                                <Button href="/admin/createEmployee" className="App_button"><h5>Add new</h5></Button>
                        </Box>
                        
                </Box>
                </>
        )
}

export default HomePageAdmin
