 const Err404=()=>{
    return(
        <div >
            <svg style={{ maxWidth : "540px" }} viewBox="0 0 865.76 682.89">
                <path d="M823.82,485.84c0,4.51-16.77,8.32-40,9.64-5.64.32-11.65.5-17.89.5-6.72,0-13.17-.2-19.17-.57-22.55-1.39-38.72-5.15-38.72-9.57s17.26-8.44,41-9.7c5.34-.28,11-.44,16.89-.44,7.07,0,13.85.23,20.11.63C808.11,477.76,823.82,481.47,823.82,485.84Z" fill="#FFBF41" data-secondary="true"></path>
                <g opacity="0.2">
                <path d="M823.82,485.84c0,4.51-16.77,8.32-40,9.64-5.64.32-11.65.5-17.89.5-6.72,0-13.17-.2-19.17-.57-22.55-1.39-38.72-5.15-38.72-9.57s17.26-8.44,41-9.7c5.34-.28,11-.44,16.89-.44,7.07,0,13.85.23,20.11.63C808.11,477.76,823.82,481.47,823.82,485.84Z" fill="#fff"></path>
                </g>
                <path d="M655.16,484.37c0,11.28-37.7,21.17-94.45,26.77-31.26,3.1-68.31,4.88-108,4.88-41,0-79.14-1.9-111-5.18-55.08-5.66-91.44-15.39-91.44-26.47s36.36-20.81,91.44-26.47c31.87-3.28,70-5.18,111-5.18,39.7,0,76.75,1.78,108,4.88C617.46,463.2,655.16,473.09,655.16,484.37Z" fill="#FFBF41" data-secondary="true"></path>
                <g opacity="0.2">
                <path d="M655.16,484.37c0,11.28-37.7,21.17-94.45,26.77-31.26,3.1-68.31,4.88-108,4.88-41,0-79.14-1.9-111-5.18-55.08-5.66-91.44-15.39-91.44-26.47s36.36-20.81,91.44-26.47c31.87-3.28,70-5.18,111-5.18,39.7,0,76.75,1.78,108,4.88C617.46,463.2,655.16,473.09,655.16,484.37Z" fill="#fff"></path>
                </g>
                <path d="M554.81,414.47H566a6.64,6.64,0,0,1,6.64,6.64v25.34a6.64,6.64,0,0,1-6.64,6.64H554.81a0,0,0,0,1,0,0V414.47A0,0,0,0,1,554.81,414.47Z" fill="#3c2c5b"></path>
                <path d="M328.95,414.47h11.22a6.64,6.64,0,0,1,6.64,6.64v25.34a6.64,6.64,0,0,1-6.64,6.64H328.95a0,0,0,0,1,0,0V414.47A0,0,0,0,1,328.95,414.47Z" transform="translate(675.75 867.55) rotate(-180)" fill="#3c2c5b"></path>
                <path d="M343.37,414.76h-1v-2.7a19.89,19.89,0,0,0-19.87-19.87l-50.67.31v-1l50.68-.31a20.89,20.89,0,0,1,20.87,20.87Z" fill="#3c2c5b"></path>
                <path d="M555.89,411.78h-1v-1.42a20.89,20.89,0,0,1,20.86-20.87l50.68.32v1l-50.68-.32a19.89,19.89,0,0,0-19.86,19.87Z" fill="#3c2c5b"></path>
                <rect x="240.01" y="385.22" width="15.3" height="1" fill="#3c2c5b"></rect>
                <rect x="240.01" y="399.99" width="15.3" height="1" fill="#3c2c5b"></rect>
                <polygon points="266.34 496.96 265.34 496.96 265.34 443.87 328.95 443.87 328.95 444.87 266.34 444.87 266.34 496.96" fill="#3c2c5b"></polygon>
                <polygon points="633.16 498.48 632.16 498.48 632.16 443.41 572.2 443.41 572.2 442.41 633.16 442.41 633.16 498.48" fill="#3c2c5b"></polygon>
                <path d="M238,486.87c0,4.5-16.77,8.32-40,9.63l-2,.11c-5.06.25-10.4.39-15.91.39-6.35,0-12.47-.17-18.19-.51-.33,0-.66,0-1-.06-22.55-1.39-38.72-5.14-38.72-9.56s16.63-8.3,39.7-9.63l1.3-.07c5.33-.29,11-.44,16.89-.44,5.52,0,10.86.14,15.91.39,1.43.07,2.82.15,4.2.24C222.31,478.78,238,482.49,238,486.87Z" fill="#FFBF41" data-secondary="true"></path>
                <g opacity="0.2">
                <path d="M238,486.87c0,4.5-16.77,8.32-40,9.63l-2,.11c-5.06.25-10.4.39-15.91.39-6.35,0-12.47-.17-18.19-.51-.33,0-.66,0-1-.06-22.55-1.39-38.72-5.14-38.72-9.56s16.63-8.3,39.7-9.63l1.3-.07c5.33-.29,11-.44,16.89-.44,5.52,0,10.86.14,15.91.39,1.43.07,2.82.15,4.2.24C222.31,478.78,238,482.49,238,486.87Z" fill="#fff"></path>
                </g>
                <path d="M793.82,413.17l22.39-168.55-41-5.06-144,156.3-2.12,23.23,124.62,17.75-4.63,39.3-2.28,19.27c6,.37,12.45.57,19.17.57,6.24,0,12.25-.18,17.89-.5l2.22-19.15,4-34,37.6,4.22,3-30ZM758.47,409l-88.63-14.26L775.28,280.12Z" fill="#E6392B" data-primary="true"></path>
                <path d="M196.06,413.83V245.74H155.25L35.19,419.59v24.49H162v52.41c5.72.34,11.84.51,18.19.51,5.51,0,10.85-.14,15.91-.39v-53.5h39.38V413.83Zm-34.09,1-86.93-1L162,283.2Z" fill="#E6392B" data-primary="true"></path>
                <path d="M642.84,367.39a19.87,19.87,0,1,0,19.87,19.87A19.87,19.87,0,0,0,642.84,367.39Zm-.11,8.7c5,0,9,1.62,9,3.61s-4,3.61-9,3.61-9-1.62-9-3.61S637.75,376.09,642.73,376.09Zm.23,22.33c-5,0-9-1.61-9-3.6s4-3.61,9-3.61,9,1.61,9,3.61S647.94,398.42,643,398.42Z" fill="#FFBF41" data-secondary="true"></path>
                <rect x="445.95" y="312.27" width="10.49" height="28.43" fill="#E6392B" data-primary="true"></rect>
                <path d="M704.32,228.72c-5.91,36.24-59.19,53.65-59.19,53.65C588,308.45,531.81,298,528.55,297.37l-.14,0L506.2,314.92,508.27,286c-48.56-48,88.83-109,88.83-109,84.19-33.05,103.3,25.32,103.3,25.32C704.65,212,705.6,220.82,704.32,228.72Z" fill="#FFBF41" data-secondary="true"></path>
                <g opacity="0.2">
                <path d="M704.32,228.72c-5.91,36.24-59.19,53.65-59.19,53.65C588,308.45,531.81,298,528.55,297.37c3.31.37,65.53,6.93,124.33-21.71,0,0,35.63-17.05,51.13-47Z" fill="#fff"></path>
                </g>
                <path d="M554.75,209.55s99.68-42.36,124.47-20.66c0,0-10.83-31.5-66.59-9.94a64.83,64.83,0,0,0-7.52,3.48Z" fill="#fff"></path>
                <path d="M560.71,443.68v67.46c-31.26,3.1-68.31,4.88-108,4.88-41,0-79.14-1.9-111-5.18V443.68a109.51,109.51,0,0,1,109.5-109.51h0A109.51,109.51,0,0,1,560.71,443.68Z" fill="#E6392B" data-primary="true"></path>
                <rect x="341.81" y="383.22" width="218.89" height="100.76" rx="47.99" fill="#FFBF41" data-secondary="true"></rect>
                <g opacity="0.1">
                <rect x="352.23" y="393.65" width="195.15" height="79.33" rx="37.79"></rect>
                </g>
                <circle cx="409.7" cy="434.61" r="15.98" fill="#fff"></circle>
                <circle cx="488.59" cy="434.61" r="15.98" fill="#fff"></circle>
                <circle cx="491.91" cy="435.02" r="6.02" fill="#3c2c5b"></circle>
                <circle cx="407.63" cy="431.7" r="6.02" fill="#3c2c5b"></circle>
                <circle cx="451.2" cy="303.98" r="15.74" fill="#E6392B" data-primary="true"></circle>
                <path d="M253.51,377.17h9.07a9.56,9.56,0,0,1,9.56,9.56v12.13a9.56,9.56,0,0,1-9.56,9.56h-9.07a0,0,0,0,1,0,0V377.17A0,0,0,0,1,253.51,377.17Z" fill="#FFBF41" data-secondary="true"></path>
                <path d="M551.1,265.31a12.15,12.15,0,0,1-6-2.47,13.63,13.63,0,0,1-5-12.22,12.17,12.17,0,0,1,2.57-6,13.49,13.49,0,0,1,11.94-4.91,12,12,0,0,1,6,2.45,13.73,13.73,0,0,1,5,12.23,12.11,12.11,0,0,1-2.54,6,13.1,13.1,0,0,1-5.37,4A12.91,12.91,0,0,1,551.1,265.31Zm8.9-7a7.88,7.88,0,0,0,1.58-4.16,10.51,10.51,0,0,0-.84-4.94,10.32,10.32,0,0,0-2.87-4.09,7.85,7.85,0,0,0-4-1.81,8.85,8.85,0,0,0-4.53.66,8.73,8.73,0,0,0-3.68,2.72,7.85,7.85,0,0,0-1.6,4.13,10.48,10.48,0,0,0,.84,4.92,10.32,10.32,0,0,0,2.88,4.1,7.81,7.81,0,0,0,4,1.84,8.59,8.59,0,0,0,4.53-.63A8.71,8.71,0,0,0,560,258.34Z" fill="#3c2c5b"></path>
                <path d="M576.07,255.51a9.14,9.14,0,0,1-4.67-2,10.77,10.77,0,0,1-3.25-4.49,10.65,10.65,0,0,1-.81-5.48,9.16,9.16,0,0,1,2-4.72,10.68,10.68,0,0,1,4.29-3.13,10.53,10.53,0,0,1,5.25-.79,9.12,9.12,0,0,1,4.74,2,11.35,11.35,0,0,1,4.06,9.95,9.43,9.43,0,0,1-2.07,4.77,10.79,10.79,0,0,1-4.34,3.17A10.23,10.23,0,0,1,576.07,255.51Zm6.3-5.89a5.76,5.76,0,0,0,1.22-3,7.32,7.32,0,0,0-.6-3.74,7.56,7.56,0,0,0-2.16-3.07,5.51,5.51,0,0,0-2.89-1.24,6.12,6.12,0,0,0-5.46,2.24,5.43,5.43,0,0,0-1.12,2.9,7.75,7.75,0,0,0,.66,3.69,6.92,6.92,0,0,0,3.4,4,5.43,5.43,0,0,0,4.48.05A6.16,6.16,0,0,0,582.37,249.62Z" fill="#3c2c5b"></path>
                <path d="M597.36,246.76a9.13,9.13,0,0,1-4.68-2,10.93,10.93,0,0,1-3.25-4.49,10.65,10.65,0,0,1-.81-5.48,9.14,9.14,0,0,1,2-4.72,10.45,10.45,0,0,1,4.29-3.13,10.61,10.61,0,0,1,5.25-.8,9.36,9.36,0,0,1,4.74,2,10.7,10.7,0,0,1,3.28,4.47,10.53,10.53,0,0,1,.77,5.49,9.51,9.51,0,0,1-2.07,4.77,10.75,10.75,0,0,1-4.33,3.17A10.26,10.26,0,0,1,597.36,246.76Zm6.29-5.89a5.71,5.71,0,0,0,1.22-3,8.37,8.37,0,0,0-2.76-6.8,5.61,5.61,0,0,0-2.89-1.25,6,6,0,0,0-3,.43,5.87,5.87,0,0,0-2.43,1.82,5.4,5.4,0,0,0-1.13,2.9,7.75,7.75,0,0,0,.66,3.69,7,7,0,0,0,3.4,4,5.41,5.41,0,0,0,4.49,0A6,6,0,0,0,603.65,240.87Z" fill="#3c2c5b"></path>
                <path d="M614.84,219.77a8.88,8.88,0,0,1,3.42-2.42,9.2,9.2,0,0,1,4.87-.62,9.11,9.11,0,0,1,4.49,2,11.12,11.12,0,0,1,3.15,4.44,11.41,11.41,0,0,1,.9,5.42,9.14,9.14,0,0,1-1.72,4.68,8.86,8.86,0,0,1-3.89,3A8.66,8.66,0,0,1,622,237a9,9,0,0,1-3.54-1l4.69,11.41-3.8,1.56-11.16-27.12,3.81-1.56,1.11,2.7A8.33,8.33,0,0,1,614.84,219.77Zm9.92,2.07a5.73,5.73,0,0,0-2.93-1.25,6,6,0,0,0-3.05.41,5.8,5.8,0,0,0-2.41,1.85,5.9,5.9,0,0,0-1.2,3,8,8,0,0,0,2.73,6.62,5.85,5.85,0,0,0,8.42-1,6,6,0,0,0,1.17-3,7.54,7.54,0,0,0-.59-3.66A7,7,0,0,0,624.76,221.84Z" fill="#3c2c5b"></path>
                <path d="M640.94,229.3a7.45,7.45,0,0,1-3.6-1,5.77,5.77,0,0,1-2.31-2.58l3.94-1.62a2.86,2.86,0,0,0,1.92,1.49,4.34,4.34,0,0,0,2.87-.27,4.59,4.59,0,0,0,2.23-1.63,2,2,0,0,0,.24-2,1.77,1.77,0,0,0-1.62-1.13,18.89,18.89,0,0,0-3.57.18,32.1,32.1,0,0,1-3.82.28,6.33,6.33,0,0,1-2.93-.71,4.62,4.62,0,0,1-2.14-2.51,4.93,4.93,0,0,1-.24-3,6.27,6.27,0,0,1,1.7-2.94,10.14,10.14,0,0,1,3.43-2.23,8.31,8.31,0,0,1,5.71-.49,6.13,6.13,0,0,1,3.82,3.47l-3.8,1.57a2.9,2.9,0,0,0-1.81-1.56,4.08,4.08,0,0,0-2.74.27,4.33,4.33,0,0,0-2.07,1.51,1.94,1.94,0,0,0-.18,1.83,1.77,1.77,0,0,0,1,1,4.33,4.33,0,0,0,1.63.25c.59,0,1.44-.1,2.56-.22a27.93,27.93,0,0,1,3.73-.27,6.66,6.66,0,0,1,2.89.71,4.68,4.68,0,0,1,2.13,2.44,5.14,5.14,0,0,1,.28,3.16,6.23,6.23,0,0,1-1.65,2.94,10.91,10.91,0,0,1-7.64,3Z" fill="#3c2c5b"></path>
                <path d="M651.4,198.46l6.22,16.36-3.17,1.3-7.09-16Zm5.71,24.21a2.69,2.69,0,0,1-1.43-3.47,2.58,2.58,0,0,1,1.45-1.41,2.5,2.5,0,0,1,2,0,2.7,2.7,0,0,1,1.42,3.47,2.46,2.46,0,0,1-1.41,1.4A2.53,2.53,0,0,1,657.11,222.67Z" fill="#3c2c5b"></path>
            </svg>
        </div>
    )
}
export default Err404