import { Container } from "react-bootstrap";
import { useSelector } from 'react-redux';
import logo from '../assets/images/LOGO.png';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import {useState} from 'react'
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Down = () =>{
    const color = useSelector((state) => state.counter.mode);
    const [coped,setCoped] = useState (false);
    /*function test (){
        setCoped(true);
        const textarea = document.createElement("textarea");
        textarea.value = "+7 918 679-31-15";
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
    }*/



    return(
        
        <div dir={"ltr"} className="contact mt-5 p-5">
            <Container className="contact-container d-flex justify-content-between">
                <div className="mb-3">
                    <h2 className="logo text-start ">
                    { color === "light" ? (<img
                        alt="Samco"
                        src="https://rest.istanbulru.com/api/getLogoImg"
                        width="160"
                        height="80"
                        className="d-inline-block align-top"
                        />) :
                    (<img
                        alt="Samco"
                        src="https://rest.istanbulru.com/api/getLogoImg"
                        width="160"
                        height="80"
                        className="d-inline-block align-top"
                        />)}
                    </h2>
                    <div className="text2" >info@istanbulru.com</div>
                    <div className="text3" > +7 918 679-31-15 </div><br/>
                        <a href="/" target="_blank" class="market-btn apple-btn" role="button">
                            <span class="market-button-subtitle">Download on the</span>
                            <span class="market-button-title">App Store</span>
                        </a>
                        <br/>
                        <a href="/istanbul.apk" target="_blank" class="market-btn google-btn" role="button">
                            <span class="market-button-subtitle">Download on the</span>
                            <span class="market-button-title">Google Play</span>
                        </a>
                    </div>
                    <div className="d-flex flex-column mt-lg-4 justify-content-center align-items-center">
                        <h4></h4>
                        <ul className="social mt-3 d-flex align-items-center justify-content-center">
                            
                                <a target="_blank" href="" class="facebook" style={{ color: "white" }}>
                                    VK
                                </a>
                            
                                
                                <a target="_blank" href="https://instagram.com/istanbul.krd" class="instagram">
                                    <InstagramIcon  style={{ fill: "white" }} />
                                </a>
                                <a target="_blank" href="https://wa.me/message/5XCSFDRKRTZQA1?src=qr" class="twitter">
                                    <WhatsAppIcon style={{ fill: "white" }}/>
                                </a>
                                <a target="_blank" href="https://t.me/istanbulkrd" class="telegram">
                                    <TelegramIcon  style={{ fill: "white" }} />
                                </a>
                                
                        </ul>
                    </div>
            </Container>
            <Col className="center">
            
                developed by <Nav.Link style={{ display :"inline" }} variant="link" href="mailto:homseahmad07@gmail.com" > Eng.Ahmad</Nav.Link>
            </Col>
            
        </div>
    )
}
export default Down