import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSelector } from 'react-redux';
import axios from "axios";
import {useState,useEffect} from 'react';
import { useParams } from "react-router-dom";

const UserData =()=>{
    const param = useParams();
    const [data,setData] = useState({});
    const [req,setReq] = useState([]);
    const Lang = useSelector((state) => state.counter.language);
    useEffect(() => {
        axios.get("https://rest.istanbulru.com/api/showUserData",{
            headers: {
                'Content-Type' : 'application/json',
                'Authorization' : 'Bearer ' +param.token 
            }
        })
            .then((response) => {
                console.log(response.data);
                setData(response.data.user_data[0]);
                setReq(response.data.requests)
            })
            .catch((error) => {
                console.log(error)
            });
    }, []);

    return (
        <Container>
            <br/><br/>
            <Row className="justify-content-center" >
            
                <Col style={{     margin: "15px" }} lg={12}>
                    user name : {data.name}
                </Col>
                <Col style={{     margin: "15px" }} lg={12}>
                    email : {data.email}
                </Col>
                <Col style={{     margin: "15px" }} lg={12}>
                    phone numper : {data.phone_no}
                </Col>
                <Col style={{     margin: "15px" }} lg={12}>
                    join date : {data.created_at}
                </Col>
                <Col style={{     margin: "15px" }} lg={12}>
                    total request : {req.length}
                </Col>

            </Row>
        </Container>
    )
}
export default UserData